import React from 'react';
import styled from 'styled-components';
import MeetLouiseImageBackground from '../assets/svg/MeetLouiseImageBackground.svg';
import NotFoundImage from '../assets/svg/NotFoundImage.svg';
import PristinePolishLogo from '../components/common/PristinePolishLogo';

const Container = styled.div`
  display: grid;
  place-items: center;
  text-align: center;

  .mainContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 1em;
  }

  .blob {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 90vh;
    z-index: -1;
  }
`;

const NotFoundPage = () => (
  <Container>
    <div className="mainContent">
      <h2>404: Not Found</h2>
      <p>Looks like you've taken a wrong turn</p>
      <NotFoundImage />
      <a href="/">Go Home</a>
      <PristinePolishLogo />
    </div>
    <MeetLouiseImageBackground className="blob" />
  </Container>
);

export default NotFoundPage;
